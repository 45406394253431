<template>
    <div class="work">
        <div class="banner">
            <img src="../assets/PC/business_img_banner.png" alt="">
            <div class="text" :style="textLeft">
                <h2>升级生态</h2>
                <p>将百姓、政府、商业及出行工具高效连接并升级原有生态</p>
            </div>
        </div>
        <div class="bannerH5">
            <h3>升级生态</h3>
            <h5>将百姓、政府、商业及出行工具高效连接
并升级原有生态</h5>
        </div>

        <div class="tap">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="面向用户" name="first">
                    <div class="card" id="work1">
                        <div class="title">生活出行</div>
                        <p>依蓝云不断探索更适合三四五线及县域城市生活出行的数字共享平台，让百姓感受智慧生活服务带来幸福感的同时助力城市数字化升级。</p>
                        <ul class="ul">
                            <li @click="dialog(0)">
                                <img src="../assets/PC/business_img_1.png" alt="">
                                <div class="tit">
                                    可蚁点
                                </div>
                                <p>可蚁点，点亮生活！</p>
                            </li>
                            <!-- <li @click="dialog(1)">
                                <img src="../assets/PC/business_img_2.png" alt="">
                                <div class="tit">
                                    咱县打车
                                </div>
                                <p>安全而有情感的生活出行！</p>
                            </li> -->
                        </ul>
                    </div>
                    <div class="card" id="work2">
                        <div class="title">客运定制</div>
                        <p>依蓝云以创新的方式帮助三四五线及县域城市传统客运模式转型，从站到站转型为门到门服务，打造人车信息互通交流平台。 </p>
                        <ul class="ul">
                            <li @click="dialog(2)">
                                <img src="../assets/PC/business_img_3.png" alt="">
                                <div class="tit">
                                    掌上巴士
                                </div>
                                <p>一个新的客运出行方式</p>
                            </li>
                        </ul>
                    </div>
                    <div class="card" id="work3">
                        <div class="title">儿童教育</div>
                        <p>在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任，全面负责生命树童书网的开发和云计算方面的工作。</p>
                        <ul class="ul">
                            <li @click="dialog(3)">
                                <img src="../assets/PC/business_img_4.png" alt="">
                                <div class="tit">
                                    生命树童书网
                                </div>
                                <p>用高品质的儿童读物弘扬中华文化</p>
                            </li>
                        </ul>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="面向企业" name="second">
                    <div class="second">
                        <div class="card" id="work4">
                            <div class="title">智慧交通</div>
                            <p>专注智慧交通场景应用解决方案。</p>
                            <div class="card_con">
                                <img src="../assets/PC/business_img_5.png" alt="">
                                <div class="text">可蚁点企业版</div>
                                <div class="name">灵活出行降低空载</div>
                                <div class="tip">(研发中，敬请期待)</div>
                            </div>
                        </div>
                        <div class="card" id="work5"  @click="dialog(5)">
                            <div class="title">云计算服务</div>
                            <p>依蓝云创立于2006年，采用国产芯片为主的服务器集群，提供在线可视化服务。依蓝云拥有安全、可靠的计算和数据处理能力，服务于制造、金融、 政务、
交通等众多领域的政企事业单位，包括哈尔滨财政部门、吉林省交通部门等政府单位，秋林集团、海信集团等企业单位，以及中关村集团等大型集团公司。
目前依蓝云在交通出行应用场景中保持着百万级订单稳定运行的记录。</p>
                            <div class="card_con">
                                <img src="../assets/PC/business_img_6.png" alt="">
                                <div class="text">依蓝云计算</div>
                                <div class="name">国产云上依蓝云</div>
                            </div>
                        </div>
                        <div class="card" id="work6">
                            <div class="title">协同办公</div>
                            <p>依蓝云不断追求软件研发进程透明化，合作流程简单化，基于OKR工作法打造数字化协同平台，以促进研发团队与合作需求方高效交流达成合作。</p>
                            <div class="card_con">
                                <img src="../assets/PC/business_img_7.png" alt="">
                                <div class="text">依蓝云集结号</div>
                                <div class="name">集优质团队链政企需求</div>
                                <div class="tip">(研发中，敬请期待)</div>
                            </div>
                        </div>
                        <div class="card" id="work7"  @click="dialog(7)">
                            <div class="title">需求定制</div>
                            <p>依蓝云专注于，交通、制造业、购物领域的信息化解决方案及应用层的定制研发服务。</p>
                            <div class="imgList">
                                <ul>
                                    <li>
                                        <img src="../assets/PC/business_ic_1.png" alt="">
                                        <span>云计算技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_2.png" alt="">
                                        <span>大数据技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_3.png" alt="">
                                        <span>云安全技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_4.png" alt="">
                                        <span>分布式技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_5.png" alt="">
                                        <span>云存储技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_6.png" alt="">
                                        <span>软开发技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_7.png" alt="">
                                        <span>物联网技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_8.png" alt="">
                                        <span>云通讯技术</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="card_con">
                                <img style="width: 120px;" src="../assets/PC/business_img_8.png" alt="">
                                <div class="text">依蓝云定制</div>
                                <div class="name">用优秀创意解决行业难题</div>
                            </div>
                        </div>
                    </div>
                    <div class="secondH5">
                        <div class="card">
                            <div class="title">智慧交通</div>
                            <img src="../assets/PC/business_img_5.png" alt="">
                            <div class="text">可蚁点企业版,灵活出行降低空载!</div>
                            <p>专注智慧交通场景应用解决方案。</p>
                            <div class="tip">研发中，敬请期待</div> 
                        </div>
                        <div class="card" >
                            <div class="title">云计算服务</div>
                                <img src="../assets/PC/business_img_6.png" alt="">
                                <div class="text">依蓝云计算,国产云上依蓝云!</div>
                            <p>依蓝云创立于2006年，采用国产芯片为主的服务器集群，提供在线可视化服务。依蓝云拥有安全、可靠的计算和数据处理能力，服务于制造、金融、政务、
交通等众多领域的政企事业单位，包括哈尔滨财政部门、吉林省交通部门等政府单位，秋林集团、海信集团等企业单位，以及中关村集团等大型集团公司。
目前依蓝云在交通出行应用场景中保持着百万级订单稳定运行的记录。</p>
                                <div class="herf">
                                    <a href="http://cloud.uyl.cn/" target="_blank">进入官网 ></a>
                                </div>
                        </div>
                        <div class="card" >
                            <div class="title">协同办公</div>
                                <img src="../assets/PC/business_img_7.png" alt="">
                                <div class="text">依蓝云集结号,集优质团队链政企需求!</div>
                                <p>依蓝云不断追求软件研发进程透明化，合作流程简单化，基于OKR工作法打造数字化协同平台，以促进研发团队与合作需求方高效交流达成合作。</p>
                                <div class="tip">研发中，敬请期待</div>
                        </div>
                        <div class="card" >
                            <div class="title">需求定制</div>                                
                                <img class="img" src="../assets/PC/business_img_8.png" alt="">
                                <div class="text">依蓝云定制,用优秀创意解决行业难题!</div>
                            <p>依蓝云专注于，交通、制造业、购物领域的信息化解决方案及应用层的定制研发服务。</p>
                            <div class="imgList">
                                <ul>
                                    <li>
                                        <img src="../assets/H5/business_ic_1.png" alt="">
                                        <span>云计算技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/H5/business_ic_2.png" alt="">
                                        <span>大数据技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/H5/business_ic_3.png" alt="">
                                        <span>云安全技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/H5/business_ic_4.png" alt="">
                                        <span>分布式技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/H5/business_ic_5.png" alt="">
                                        <span>云存储技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/H5/business_ic_6.png" alt="">
                                        <span>软开发技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_7.png" alt="">
                                        <span>物联网技术</span>
                                    </li>
                                    <li>
                                        <img src="../assets/PC/business_ic_8.png" alt="">
                                        <span>云通讯技术</span>
                                    </li>
                                </ul>
                                <div class="herf"  @click="herf()">
                                    查看详情 >
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="依蓝云定制" v-if="activeName=='three'" name="three">
                    <div class="main">
                        <ul class="three">
                            <li v-for="(val,index) in customized" :key="index" @click="dialogDingClick(val)">
                                <img :src="val.img" alt="">
                                <img src="../assets/PC/ic_play.png" v-if="val.type==2" class="play" alt="">
                                <div class="title">{{val.title}}</div>
                                <p>{{val.text}}</p>
                            </li>
                        </ul>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- 合作伙伴 -->
        <div class="partner" v-if="activeName=='second'">
            <div class="title">合作伙伴</div>
            <div class="imgs">
                <div class="main">
                    <ul>
                        <li><img src="../assets/PC/partner_img_1.png" alt=""></li>
                        <li> <img src="../assets/PC/partner_img_2.png" alt=""></li>
                        <li><img src="../assets/PC/partner_img_33.png" alt=""></li>
                        <li><img src="../assets/PC/partner_img_4.png" alt=""></li>
                        <li><img src="../assets/PC/partner_img_5.png" alt=""></li>
                        <li><img style="margin-right:0" src="../assets/PC/partner_img_6.png" alt=""></li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 底部滑动 -->
        <div class="wrap_con" v-if="activeName=='three'">
            <div class="wrap"></div>
        </div>


        <!-- 弹框内容 -->
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            >
            <div class="dialog" v-if="alertDateCon.type==1">
                <img class="logo" :style="(viewType&&alertDateCon.title)=='生命树童书网'?'width: 2.2rem;':''" :src="alertDateCon.img" alt="">
                <h3>{{alertDateCon.title}}</h3>
                <div class="title">{{alertDateCon.text}}</div>
                <p>{{alertDateCon.p}}</p>
                <!-- viewType -->
                <div class="alertDateEr" v-if="alertDateCon.alertDateEr">
                    <div class="alertDateErCon">
                        <img :src="alertDateCon.alertDateEr" alt="">
                        <span>微信扫码立即体验</span>
                    </div>
                </div>
                <div v-if="alertDateCon.herf!=''">
                    <div class="herf" v-if="!(viewType&&alertDateCon.herfH5)"><a target="_blank" :href="alertDateCon.herf">{{alertDateCon.herfText}}></a></div>
                    <div class="herf" v-if="viewType&&alertDateCon.herfH5"><a target="_blank" :href="alertDateCon.herfH5">{{alertDateCon.herfText}}></a></div>
                </div>
            </div>
            <div class="dialog" v-if="alertDateCon.type==2">
                <div class="dialog_type">
                    <img class="logo" :style="alertDateCon.herfText=='查看详情'?'width: 124px;':''" :src="alertDateCon.img" alt="">
                    <h3>{{alertDateCon.title}}</h3>
                    <div class="title">{{alertDateCon.text}}</div>
                    <p>{{alertDateCon.p}}</p>
                    <div class="herf" v-if="alertDateCon.herfText!='查看详情'"><a target="_blank" :href="alertDateCon.herf">{{alertDateCon.herfText}}></a></div>
                    <div class="herf" v-if="alertDateCon.herfText=='查看详情'" @click="herf()">
                        {{alertDateCon.herfText}}>
                    </div>
                </div>
            </div>
            
        </el-dialog>
        <!-- 定制 内容 -->
        <el-dialog
            title=""
            :visible.sync="dialogDing"
            :before-close="videoPlayer"
            >
            <div class="dialogDing" v-if="dialogDingObj.type==1">
                <img class="logoMobile" :src="dialogDingObj.guide" alt="">
                <img class="logo" v-if="dialogDingObj.title!='掌上巴士'" :src="dialogDingObj.logo" :style="(dialogDingObj.title=='可蚁点企业版'&&viewType)?'width: 1.6rem;':''" alt="">
                <img class="logo" v-if="dialogDingObj.title=='掌上巴士'" :src="dialogDingObj.logo" :style="viewType?'width: 0.64rem;':''" alt="">
                <h3>{{dialogDingObj.title}}</h3>
                <div class="title">{{dialogDingObj.text}}</div>
                <p>{{dialogDingObj.tip}}</p>
                <div class="er" v-if="dialogDingObj.foot=='er'">
                    <img :src="dialogDingObj.foot_er" alt="">
                    <em>微信扫码立即体验</em>
                </div>
                <div class="text" v-if="dialogDingObj.foot=='text'">
                    <div>已上架应用商店：</div>
                    <div >{{dialogDingObj.footText}}</div>
                </div>
                
                    <div v-if="dialogDingObj.herf">
                        <div class="herf" v-if="!(viewType&&dialogDingObj.herfH5)"><a target="_blank" :href="dialogDingObj.herf">进入网站 ></a></div>
                        <div class="herf" v-if="viewType&&dialogDingObj.herfH5"><a target="_blank" :href="dialogDingObj.herfH5">进入网站 ></a></div>
                    </div>
            </div>
            
            <!-- 视频类型 -->
            <div class="dialogDingVide" v-if="dialogDingObj.type==2">
                <div class="title">{{dialogDingObj.title}}</div>
                <div class="text">{{dialogDingObj.text}}</div>
                <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="dialogDingObj.playerOptions">
                </video-player>
                <p>{{dialogDingObj.tip}}</p>
            </div>
            
        </el-dialog>

    </div>
</template>
<script>
export default {
    data () {
        return {
            textLeft:'left:0px',
            activeName: 'first',
            viewType:this.$look(),
            dialogVisible: false,
            host:window.location.host,
            dialogDing: false, //定制弹框
            dialogDingObj: {}, //定制弹框内容
            alertDate:[
                {
                    title:'可蚁点',
                    text:'可蚁点，点亮生活！',
                    img:require('../assets/PC/business_img_1.png'),
                    type:1,
                    herfText:'进入官网',
                    herf:'https://livingtrip.uyl.cn/',
                    p:'可蚁点平台基于“一个对话一个订单”的创新交互模式，通过升级本地交通工具（巡游出租车、小型客运巴士）服务模式实现本地交通数字化、管理智能化。另平台链接本地商超、饭店等商业载体为本地用户提供去中间环节的点对点生活服务，通过分布式存储技术、本地化部署运营为本地市场监管部门提供有效数据的同时加速数字民生的落地，继而促进本地经济内循环推动城市生活出行的发展。'
                },
                {
                    title:'咱县打车',
                    text:'安全而有情感的生活出行！',
                    img:require('../assets/PC/business_img_2.png'),
                    type:1,
                    herfText:'进入官网',
                    herf:'http://www.zdache.cn/',
                    herfH5:'https://m.zdache.cn/phone/index/home',
                    p:'为促进城市发展，构建和谐智慧的互联网生活方式，依蓝云（北京）科技有限公司根据区块链+民生思想，于2017年自主研发和运营生活、出行互联网服务平台“咱县打车”，专门为全国县域城市及四五线城市的数亿居民和旅客提供贴心而领先的互联网+智慧生活体验服务。'
                },
                {
                    title:'掌上巴士',
                    text:'一个新的客运出行方式！',
                    img:require('../assets/PC/business_img_3.png'),
                    type:1,
                    herfText:'进入网站',
                    herf:'',
                    alertDateEr:require('../assets/PC/image.jpg'),
                    p:'依蓝云以创新的方式帮助三四五线及县域城市传统客运模式转型，从站到站转型为门到门服务，打造人车信息互通交流平台。'
                },
                {
                    title:'生命树童书网',
                    text:'用高品质的儿童读物弘扬中华文化！',
                    img:require('../assets/PC/business_img_4.png'),
                    type:1,
                    herfText:'进入网站',
                    herfH5:'https://www.lifetreebooks.org.cn/',
                    herf:'https://lifetreebooks.org.cn/pc/life/index.html',
                    p:'在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任，全面负责生命树童书网的开发和云计算方面的工作。'
                },
                {
                    title:'可蚁点',
                    text:'国产云上依蓝云！',
                    img:require('../assets/PC/business_img_5.png'),
                    type:2,
                    herfText:'进入网站',
                    herf:'www.baidu.com',
                    p:'在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任，全面负责生命树童书网的开发和云计算方面的工作。'
                },
                {
                    title:'依蓝云计算',
                    text:'国产云上依蓝云！',
                    img:require('../assets/PC/business_img_6.png'),
                    type:2,
                    herfText:'进入网站',
                    herf:'http://cloud.uyl.cn/',
                    p:'依蓝云创立于2006年，采用国产芯片为主的服务器集群，提供在线可视化服务。依蓝云拥有安全、可靠的计算和数据处理能力，服务于制造、金融、 政务、交通等众多领域的政企事业单位，包括哈尔滨财政部门、吉林省交通部门等政府单位，秋林集团、海信集团等企业单位，以及中关村集团等大型集团公司。目前依蓝云在交通出行应用场景中保持着百万级订单稳定运行的记录。'
                },
                {
                    title:'集结号',
                    text:'国产云上依蓝云！',
                    img:require('../assets/PC/business_img_7.png'),
                    type:2,
                    herfText:'进入网站',
                    herf:'www.baidu.com',
                    p:'在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任，全面负责生命树童书网的开发和云计算方面的工作。'
                },
                {
                    title:'依蓝云定制',
                    text:'用优秀创意解决行业难题！',
                    img:require('../assets/PC/business_img_8.png'),
                    type:2,
                    herfText:'查看详情',
                    herf:'www.baidu.com',
                    p:'依蓝云专注于，交通、制造业、购物领域的信息化解决方案及应用层的定制研发服务。'
                },
            ],

            // 定制列表数据
            customized:[
                {
                    type:1,
                    img:require('../assets/PC/customized1.png'),
                    title:'百年秋林格瓦斯小程序',
                    text:'百年秋林商城——秋林品牌始建于1900年，从19世纪走来，已经有一百多年的风雨历程。秋林格瓦斯是真正正宗的百年老字号名企。公司主要生产秋林格瓦斯饮料、秋林爵杞、秋林谷汽、鲜酿格瓦斯、苏打气泡水等10余种产品。',
                    guide:require('../assets/PC/guide1.png'),
                    logo:require('../assets/PC/case_img_1.png'),
                    tip:'依蓝云负责百年秋林格瓦斯小程序的方案策划、小程序交互设计、大数据管理后台研发、平台数据分析及运营维护等。',
                    foot:'er',
                    foot_er:require('../assets/PC/footer.png'),
                },
                {
                    type:2,
                    img:require('../assets/PC/customized2.png'),
                    playerOptions: {
                        //播放速度
                        // playbackRates: [0.5, 1.0, 1.5, 2.0], 
                        //如果true,浏览器准备好时开始回放。
                        autoplay: false, 
                        // 默认情况下将会消除任何音频。
                        muted: true, 
                        // 导致视频一结束就重新开始。
                        loop: false, 
                        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                        preload: 'auto', 
                        language: 'zh-CN',
                        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                        aspectRatio: '16:9',
                        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                        fluid: true,
                        sources: [{
                            //类型
                            type: "video/mp4",
                            //url地址
                            src: require('../assets/PC/playerOptions2.mp4')
                        }],
                        //你的封面地址
                        poster: require('../assets/PC/view2.png'), 
                        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                        notSupportedMessage: '此视频暂无法播放，请稍后再试',
                        controlBar: {
                            timeDivider: true,
                            durationDisplay: false,
                            remainingTimeDisplay: false,
                            //全屏按钮
                            fullscreenToggle: true  
                        }
                    },
                    title:'依蓝云企业宣传片',
                    text:'依蓝云创立于2006年，是以 生活出行大数据、云计算、互联网交互设计和研发为主的创新型科技企业。公司成立至今专注为生产制造、交通、政府部门提供信息化定制及安全上以国产芯片为主的云计算平台。',
                    tip:'依蓝云负责宣传片整体的策划与拍摄，包含宣传片的脚本策划、转场、拍摄及视频后期制作，协助宣传片后期的运营推广各项工作。',
                },
                {
                    type:2,
                    img:require('../assets/PC/customized3.png'),
                    playerOptions: {
                        //播放速度
                        // playbackRates: [0.5, 1.0, 1.5, 2.0], 
                        //如果true,浏览器准备好时开始回放。
                        autoplay: false, 
                        // 默认情况下将会消除任何音频。
                        muted: true, 
                        // 导致视频一结束就重新开始。
                        loop: false, 
                        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                        preload: 'auto', 
                        language: 'zh-CN',
                        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                        aspectRatio: '16:9',
                        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                        fluid: true,
                        sources: [{
                            //类型
                            type: "video/mp4",
                            //url地址
                            src: require('../assets/PC/playerOptions1.mp4')
                        }],
                        //你的封面地址
                        poster: require('../assets/PC/view1.png'), 
                        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                        notSupportedMessage: '此视频暂无法播放，请稍后再试',
                        controlBar: {
                            timeDivider: true,
                            durationDisplay: false,
                            remainingTimeDisplay: false,
                            //全屏按钮
                            fullscreenToggle: true  
                        }
                    },
                    title:'滨滨来啦MG动画',
                    text:'哈尔滨旅游吉祥物滨滨MG动画来了，滨滨带你了解哈尔滨的旅游文化！',
                    tip:'依蓝云负责MG动画全部系列策划，包含动画各系列脚本策划、动画绘制、视频制作、后期特效制作及宣传推广工作。',
                },
                {
                    type:1,
                    img:require('../assets/PC/customized4.png'),
                    title:'咱县打车APP',
                    text:'为促进城市发展，构建和谐智慧的互联网生活方式，依蓝云（北京）科技有限公司根据区块链+民生思想，于2017年自主研发和运营生活、出行互联网服务平台“咱县打车”，专门为全国县域城市及四五线城市的数亿居民和旅客提供领先的互联网+智慧生活体验服务。',
                    guide:require('../assets/PC/guide2.png'),
                    logo:require('../assets/PC/case_img_2.png'),
                    tip:'依蓝云自主设计、研发和运营咱县打车（用户版）、咱县司机、咱县商家、咱县跑腿及咱县打车大数据管理后台。',
                    foot:'text',
                    footText:'苹果商店、华为应用商店、vivo应用商店、OPPO应用商店、小米应用商店'
                },
                {
                    type:1,
                    img:require('../assets/PC/customized5.png'),
                    title:'生命树童书网',
                    text:'在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任，全面负责生命树童书网的开发和云计算方面的工作',
                    guide:require('../assets/PC/guide3.png'),
                    logo:require('../assets/PC/case_img_3.png'),
                    tip:'依蓝云负责生命树童书网网站的方案策划、交互设计、大数据管理后台研发、平台数据分析及运营维护等。',
                    foot:'herf',
                    herf:'https://lifetreebooks.org.cn/pc/life/index.html',
                    herfH5:'https://www.lifetreebooks.org.cn/',
                },
                {
                    type:1,
                    img:require('../assets/PC/customized6.png'),
                    title:'可蚁点企业版',
                    text:'根据日常交运情况挖掘县城路线需求分布信息，结合县城公交基础设施及道路通行条件，搭建县城公共交通网络模型，进一步利用县城与城市之间路径规划合理的出行路线，为县 城与县城，县城与核心城市互通提供高效解决方案。',
                    guide:require('../assets/PC/guide4.png'),
                    logo:require('../assets/PC/case_img_4.png'),
                    tip:'依蓝云自主设计和研发可蚁点企业版客户端和可蚁点企业版大数据管理后台。',
                    foot:'none',
                },
                {
                    type:1,
                    img:require('../assets/PC/customized7.png'),
                    title:'赤峰市95128',
                    text:'为更好的服务老年群体的打车出行，响应国家推广95128电召约车服务并进行智能化升级，可蚁点平台为赤峰市接入95128电召叫车系统，为不同用户群体提供全方位的用车需求。',
                    guide:require('../assets/PC/guide5.png'),
                    logo:require('../assets/PC/case_img_4.png'),
                    tip:'依蓝云自主设计、研发和运营可蚁点（用户版）、可蚁点司机及可蚁点大数据管理后台。',
                    foot:'none',
                },
                {
                    type:1,
                    img:require('../assets/PC/customized8.png'),
                    title:'掌上巴士',
                    text:'一个新的客运出行方式，以创新的方式帮助三四五线及县域城市传统客运模式转型，从站到站转型为门到门服务，打造人车信息互通交流平台。',
                    guide:require('../assets/PC/guide6.png'),
                    logo:require('../assets/PC/case_img_5.png'),
                    tip:'依蓝云负责掌上巴士客运系统的方案策划、APP/小程序交互设计、大数据管理后台研发、运营维护等。',
                    foot:'er',
                    foot_er:require('../assets/PC/image.jpg'),
                },
            ],
            alertDateCon:{},
            scrollTopNumber:[556,1161,1667,500,1134,1667,2115]
        }
    },
    watch: {
        "$route": "fetchDate",
    },
    mounted () {
        
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:20px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:20px'
                }
            })();
        };
        
        let this_=this;
        
            // herfIndex tap切换类型
            if(this_.$route.query.herfIndex){
                if(this_.$route.query.herfIndex==0){
                    this_.activeName='first'
                    setTimeout(function(){
                        if(this_.$look()){
                            let overHeight=document.querySelectorAll(".bannerH5")[0].offsetHeight+document.querySelectorAll(".el-tabs__header")[0].offsetHeight
                            this_.scrollTopNumber=[
                                overHeight,
                                document.querySelector('#work2').offsetTop+overHeight,
                                document.querySelector('#work3').offsetTop+overHeight,
                                document.querySelector('#work4').offsetTop+overHeight,
                                document.querySelector('#work5').offsetTop+overHeight,
                                document.querySelector('#work6').offsetTop+overHeight,
                                document.querySelector('#work7').offsetTop+overHeight,
                                ]
                            document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.id]
                            window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.id])
                        }else{

                            this_.scrollTopNumber=[
                                550,
                                document.querySelector('#work2').offsetTop+550,
                                document.querySelector('#work3').offsetTop+550,
                                document.querySelector('#work4').offsetTop+550,
                                document.querySelector('#work5').offsetTop+550,
                                document.querySelector('#work6').offsetTop+550,
                                document.querySelector('#work7').offsetTop+550,
                                ]
                            document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.id]
                            window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.id])
                        }
                    },100)
                    // this_.$route.query.herfIndex
                }else if(this_.$route.query.herfIndex==1){
                    this_.activeName='second'
                    setTimeout(function(){
                        if(this_.$look()){
                            let overHeight=document.querySelectorAll(".bannerH5")[0].offsetHeight+document.querySelectorAll(".el-tabs__header")[0].offsetHeight
                            this_.scrollTopNumber=[
                                overHeight,
                                document.querySelector('#work2').offsetTop+overHeight,
                                document.querySelector('#work3').offsetTop+overHeight,
                                document.querySelector('#work4').offsetTop+overHeight,
                                document.querySelector('#work5').offsetTop+overHeight,
                                document.querySelector('#work6').offsetTop+overHeight,
                                document.querySelector('#work7').offsetTop+overHeight,
                                ]
                            document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.id]
                            window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.id])
                        }else{

                            this_.scrollTopNumber=[
                                document.querySelector('#work1').offsetTop+550,
                                document.querySelector('#work2').offsetTop+550,
                                document.querySelector('#work3').offsetTop+550,
                                document.querySelector('#work4').offsetTop+550,
                                document.querySelector('#work5').offsetTop+550,
                                document.querySelector('#work6').offsetTop+550,
                                document.querySelector('#work7').offsetTop+550,
                                ]
                            document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.id]
                            window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.id])
                        }
                    },100)
                    // this_.$route.query.herfIndex
                }else{
                    this_.activeName='three'
                }
                if(this.$route.query.alertType){
                    if(this.$route.query.id==1){
                        // 客运定制
                        this.dialog(2)
                    }else{
                        this.dialog(this.$route.query.id)
                    }
                }
        }
        
    },
    methods: {
        videoPlayer(){
            this.dialogDing=false
            this.$refs.videoPlayer.player.pause()
        },
        fetchDate(){
            if(this.$route.query.herfIndex){
                if(this.$route.query.herfIndex==0){
                    this.activeName='first'
                    document.documentElement.scrollTop=this.scrollTopNumber[this.$route.query.id]
                    window.scrollTo(0, this.scrollTopNumber[this.$route.query.id])
                    // this.$route.query.herfIndex
                    console.log(this.$route.query.id)
                    
                }else if(this.$route.query.herfIndex==1){
                    this.activeName='second'
                    document.documentElement.scrollTop=this.scrollTopNumber[this.$route.query.id]
                    window.scrollTo(0, this.scrollTopNumber[this.$route.query.id])
                    // this.$route.query.herfIndex
                }else{
                    this.activeName='three'
                }
            }
        },
        handleClick(tab, event) {
            document.documentElement.scrollTop=551
            window.scrollTo(0, 551)
        },
        dialogDingClick(value){
            this.dialogDing=true
            this.dialogDingObj=value
        },
        dialog(id){
            this.dialogVisible = true
            this.alertDateCon=this.alertDate[id]
        },
        // 显示定制
        herf(){
            this.activeName='three';
            this.dialogVisible=false
            window.scrollTo(0,0)
        },
    }
}
</script>
<style lang="less" scoped>
    .work{
        /deep/ .video-js {
            background: #fff;   
        }
        // /deep/ .vjs-poster {
        //     background-size: cover;
        // }

        padding-top: 70px;
        .bannerH5{
            display: none;
        }
        /deep/.el-dialog{
            min-width: 860px;
            .el-dialog__body{
                color: #333;
            }
        }
        .banner{
            position: relative;
            width: 100%;
            img{
                width: 100%;height: 476px;
            }
                .text{
                    position: absolute;
                    left: 12%;
                    top: 50%;
                    transform: translateY(-50%);
                    h2{
                        font-size: 64px;
                        color: #fff;
                        font-weight: normal;
                    }
                    p{
                        margin-top: 30px;
                        color: #fff;
                        font-size: 24px;
                    }
                }
        }
        /deep/.el-tabs__content{
            overflow: inherit;
            max-width: 1270px;
            margin: 0 auto;
        }

        .tap{
            position: relative;
            /deep/.el-tabs__nav-scroll{
                max-width: 1270px;
                margin: 0 auto;
            }
            /deep/.el-tabs__header{
                margin-bottom: 72px;
            }
            .second{
                display: block;
            }
            .secondH5{
                display: none;
            }
            .card{
                position: relative;
                box-sizing: border-box;
                padding: 70px 50px;
                margin-bottom: 60px;
                width: 100%;
                height: 498px;
                background-color: #ffffff;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                .imgList{
                    width: 500px;
                    position: absolute;
                    left: 40px;
                    bottom: 70px;
                    li{
                        width: 110px;
                        text-align: center;
                        float: left;
                        margin-top: 30px;
                        img{
                            width: 56px;
                            height: 56px;
                        }
                        span{
                            display: inline-block;
                            font-size: 16px;
                            color: #999999;
                            margin-top: 10px;
                        }
                    }
                }
                .title{
                    font-size: 36px;
                }
                p{
                    font-size: 16px;
                    margin-top: 12px;
                }
                .ul{
                    position: absolute;
                    right: 50px;
                    bottom: 70px;
                    li{
                        text-align: center; 
                        width: 260px;
                        float: left;
                        img{
                            width: 115px;
                        }
                        .tit{
                            font-size: 24px;
                            margin-top: 20px;
                        }
                        p{
                            font-size: 16px;
                        }
                    }
                }
                .card_con{
                    text-align: right;
                    position: absolute;
                    right: 50px;
                    bottom: 70px;
                    img{
                        width: 158px;
                    }
                }
                .text{
                    font-size: 24px;
                    margin-top: 60px; 
                }
                .name{
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                .tip{
                    font-size: 14px;
                    color: #009cff;
                }
            }

            .three{
                height: 1300px;
                li{ 
                    cursor: pointer;
                    position: relative;
                    float: left;
                    width: 380px;
                    margin-right:40px;
                    min-height: 450px;
                    .play{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100px;
                        width: 40px;
                        height: 40px;
                    }
                    img{
                        width: 100%;
                        height: 240px;
                    }
                    .title{
                        font-size: 24px;
                        margin-top: 30px;
                    }
                    p{
                        font-size: 16px;
                        margin-top: 20px;
                        color: #666;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                li:nth-child(3n+3){
                    margin-right: 0;
                }
            }
        }
        .dialogDing{
            color: #333;
            position: relative;
            margin: 0 30px;
            min-height: 600px;
            .logoMobile{
                position: absolute;
                right: 0;
                width: 300px;
            }
            .herf{
                position: absolute;
                bottom: 30px;
                left: 0px;
                a{
                    color: #009cff;
                    font-size: 16px;
                }
            }
            .text{
                position: absolute;
                width: 429px;
                bottom: 30px;
                left: 0px;
                color: #009cff;
                padding-top:100px ;
            }
            .er{
                img{
                    width: 120px;
                    margin-top: 60px;
                }
                em{
                    display: block;
                    margin-top: 20px;
                    font-size: 16px;
                    color: #009cff;
                }
            }
            .logo{
                height: 60px;
            }
            h3{
                font-size: 24px;
                margin-top: 50px;
                font-weight: normal;
            }
            .title{
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 50px;
                width: 429px;
                text-align: justify;
            }
            p{
                font-size: 16px;
                padding-top: 40px;
                width: 429px;
                border-top: 1px solid #e6e6e6;
            }
        }

        .dialogDingVide{
            padding: 0 30px;
            .title{
                display: inline-block;
                font-size: 24px;
                border-bottom: 3px solid #009cff;
            }
            .text{
                margin-top: 20px;
                font-size: 16px;
                margin-bottom: 20px;
            }
            .video-player{
                width: 640px;
                margin: 0 auto;
            }
            p{
                font-size: 16px;
                margin-top: 20px;
                padding-top: 28px;
                border-top: 1px solid #e6e6e6;
                padding-bottom: 30px;
            }

        }
        .tap::after{
            content: '';
            position: absolute;
            top: 73px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #e6e6e6;
        }
        /deep/.el-tabs__item{
            position: relative;
            height: 73px;
            line-height: 73px;
            padding:0 40px;
            font-size: 16px;
        }
        /deep/.el-tabs__item:nth-child(2)::after{
            position: absolute;
            content: '';
            top: 29px;
            right: 0;
            width: 1px;
            height: 16px;
            background-color: #e6e6e6;
        }
        /deep/.el-tabs__item:nth-child(4)::after{
            position: absolute;
            content: '>';
            top: 0px;
            left: 0;
        }
        /deep/.el-tabs__nav-wrap::after{
            display: none;
        }

        .dialog{
            position: relative;
            padding:0 64px;
            overflow: hidden;
            min-height: 450px;
            color: #333;
            .alertDateEr{
                text-align: right;
                margin-top: 20px;
                height: 200px;
                .alertDateErCon{
                    position: absolute;
                    right: 64px;
                    bottom: 0;
                    width: 150px;
                    text-align: center;
                    img{
                        width: 120px;
                    }
                    span{
                        display: block;
                        margin-top: 20px;
                        font-size: 16px;
                        color: #009cff;
                    }
                }
            }
            .dialog_type{
                .logo{
                    position: relative;
                    width: 210px;
                    left: 0;
                }
                    h3{
                        padding-left:0;
                        border-top: 1px solid #e6e6e6;
                        padding-top: 40px;
                    }
                    .title{
                        padding-left:0;
                        margin-top:10px ;
                    }
                    p{
                        border: 0px;
                        padding-top: 0;
                        padding-bottom: 100px;
                        text-align: justify;
                    }
            }
            .logo{
                position: absolute;
                width: 160px;
                left: 64px;
                top: 0;
            }
            h3{
                padding-left: 200px;
                font-size: 36px;
                margin-top: 50px;
                font-weight: normal;
            }
            .title{
                padding-left: 200px;
                font-size: 24px;
                line-height: 47px;
                margin-top:20px ;
                margin-bottom: 40px;
            }
            p{
                font-size: 16px;
                padding-top:40px ;
                border-top: 1px solid #e6e6e6;
                text-align: justify;
            }
            .herf{
                cursor: pointer;
                position: absolute;
                right: 64px;
                bottom: 20px;
                color: #009cff;
                font-size: 16px;
                text-decoration: revert;
                text-decoration: underline;
                a{
                    color: #009cff;
                    font-size: 16px;
                    text-decoration: revert;
                }
            }
        }

        .partner{
            overflow: hidden;
            background:url(../assets/PC/partner_img_3.png) no-repeat ;
            background-size: 100% 100%;
            margin-top: 80px;
            .imgs{
                margin-bottom: 80px;
                ul{
                    display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    // text-align: center;
                    // width: 16.666%;
                }
                }
            }
            .title{
                color: #009cff;
                font-size: 36px;
                margin-top: 36px;
                text-align: center;
                margin-bottom: 80px;
            }
            img{
                // height: 38px;
                // margin-right: 60px;
            }
        }
        .wrap_con{
            width: 100%;
            overflow: hidden;
            .wrap {
                height: 340px;
                width: 3840px;
                background: url(../assets/PC/wrap.png) repeat-x;
                -webkit-animation:cloud 30s linear infinite;
                -o-animation:cloud 30s linear infinite;
                background-size: auto 100%;
            }
        }
        @-webkit-keyframes cloud {
        from{background-position:0% 0%}
        to{background-position:100% 100%}
        }

    }

    // 手机端样式
    #app.H5Class {
        .work{
            //播放按钮样式
            .video-player.vjs-custom-skin > .video-js .vjs-big-play-button{
            width: 60px !important;
            height:35px !important;
            line-height: 35px !important;
            border-radius: 5px !important;
            font-size: 2.5em !important;
            }
            .vjs-custom-skin > .video-js .vjs-big-play-button {
                top: 53%;
            }
            .vjs-poster{
            border-radius: 10px i !important;
            }
            width: 7.5rem;
            padding-top: 0.8rem;
            .banner{
                display: none;
            }
            .partner{
                margin-top: 0;
            }
            .bannerH5{
                overflow: hidden;
                width: 100%;
                display: block;
                height: 5.6rem;
                background:url(../assets/H5/business_img_banner.png) no-repeat;
                background-size: 100% 100%;
                h3{
                    margin-top: 1.2rem;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 400;
                    padding-left: 0.4rem;
                }
                h5{
                    margin-top: 0.3rem;
                    color: #fff;
                    padding-left: 0.4rem;
                    font-size: 0.24rem;
                    font-weight: 400;
                    width: 4.3rem;
                }
            }
            /deep/.el-dialog__headerbtn{
                font-size: 0.5rem;
                z-index: 11;
            }
            /deep/.el-dialog{
                width: 6.4rem;
                    min-width: 6.4rem;
            }
            /deep/.el-dialog__body{
                padding: 0.4rem;
                .dialog{
                    padding: 0;
                    .logo{
                        left: 0;
                    }
                }
            }
            /deep/ .el-dialog__header{
                padding: 0;
            }
            /deep/.el-tabs__item:nth-child(2)::after{
                height: 0.3rem;
                top: 0.4rem;
            }
            .tap{
                /deep/.el-tabs__header{
                    padding-left: 0.4rem;
                    margin-bottom:0
                }
                /deep/.el-tabs__item{
                    font-size: 0.3rem;
                    height: 1.1rem;
                    line-height: 1.1rem;
                    padding: 0 0.5rem;
                }
                /deep/.el-tabs__item:nth-child(2){
                    padding-left: 0;
                }
                /deep/.el-tabs__nav-scroll{
                    overflow-x: scroll;
                    transform:none
                }
                ::-webkit-scrollbar {
                    /*隐藏滚轮*/
                    display: none;
                    }
                /deep/.el-tabs__nav-next{
                    display: none;
                }
                /deep/.el-tabs__nav-prev{
                    display: none;
                }
                /deep/.el-tabs__nav-wrap.is-scrollable{
                    padding: 0 0.4rem;
                }
                .card:last-child{
                    ul{
                        border-bottom: 0.01rem solid transparent;
                    }           
                }
                .card:last-child{
                    padding-bottom: 0rem!important;
                    border-bottom: 0!important;
                }
                .card{
                    margin-top: 0;
                    height: auto;
                    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.0);
                    padding: 0.8rem 0.4rem;
                    padding-bottom: 0;
                    margin-bottom:0;
                    .title{
                        position: relative;
                        font-size: 0.34rem;
                        font-weight: 700;
                        padding-left: 0.3rem;
                        // border-left: 0.1rem solid #009cff;
                    }
                    .title::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 0.08rem;
                        height: 0.34rem;
                        background-color: #009cff;
                    }
                    p{
                        font-size: 0.24rem;
                        margin-top: 0.4rem;
                    }
                    ul{
                        position: unset;
                        margin-top: 0.6rem;
                        overflow: hidden;
                        padding-bottom: 0.4rem;
                        border-bottom: 0.01rem solid #e6e6e6;
                        li{
                            position: relative;
                            box-sizing: border-box;
                            float: revert;
                            width: 100%;
                            margin-bottom: 0.6rem;
                            overflow: hidden;
                            img{
                                width: 1.2rem;
                                height: 1.2rem;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                            .tit{
                                text-align: left;
                                padding-left:1.5rem ;
                                margin-top: 0.3rem;
                                font-size: 0.24rem;
                                font-weight: 700;
                            }
                            p{
                                text-align: left;
                                padding-left:1.5rem ;
                                margin-top: 0.2rem;
                                line-height: 2;
                            }
                        }
                        li::after{
                            position: absolute;
                            content: '';
                            background:url(../assets/H5/ic_rightarrow.png) no-repeat ;
                            background-size: 100% 100%;
                            width: 0.1rem;
                            height: 0.2rem;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        
                    }
                }
                .second{
                    display: none;
                }
                .secondH5{
                    display: block;
                    .card{
                        border-bottom: 0.01rem solid #e6e6e6;
                        padding-bottom: 0.6rem;
                        width: 6.7rem;
                        padding-left: 0;
                        padding-right: 0;
                        margin: 0 auto;
                        img{
                            height: 0.8rem;
                            margin-top: 0.8rem;
                        }
                        .text{
                            margin-top: 0.6rem;
                            font-size: 0.24rem;
                            font-weight: 700;
                        }
                        p{
                            font-size: 0.24rem;
                            text-align: justify;
                            margin-top: 0rem;
                            line-height: 0.38rem;
                        }
                        .tip{
                            text-align: right;
                            font-size: 0.3rem;
                            color: #009cff;
                            margin-top:1rem ;
                        }
                        .herf{
                            text-align: right;
                            margin-top:1rem ;font-size: 0.3rem;
                            color: #009cff;
                            a{
                                
                            font-size: 0.28rem;
                            color: #009cff;
                            }
                        }
                        .imgList{
                            position: relative;
                            left: 0;
                            width: 100%;
                            margin-top: 1.6rem;
                            ul{
                                border-bottom:0px;
                            }
                            .herf{
                                    margin-top: 0.2rem;
                                }
                            li{
                                float: left;
                                width: 25%;
                                text-align: center;
                                margin-bottom: 0;
                                margin-top: 0;
                                img{
                                    position: relative;
                                    width: 0.6rem;
                                    height: 0.6rem;
                                    margin-top:0.4rem ;
                                }
                                span{
                                    display: inline-block;
                                    width: 100%;
                                    font-size: 0.24rem;
                                    color: #999999;
                                    margin-top: 0.3rem;
                                }
                                
                            }
                            li::after{
                                display: none;
                            }
                        }
                    }
                }
                .three{
                    width: 6.7rem;
                    margin: 0 auto;
                    li:last-child{
                        border: 0;
                    }
                    li{
                        width: 100%;
                        padding: 0.7rem 0;
                        min-height:2rem;
                        border-bottom: 0.01rem solid #e6e6e6;
                        img{
                            height: 4.2rem;
                        }
                        .title{
                            font-size: 0.34rem;
                            margin-top: 0.2rem;
                        }
                        p{
                            font-size: 0.24rem;
                            margin-top: 0.1rem;
                        }
                        .play{
                            width: 0.8rem;
                            height: 0.8rem;
                            top: 2.4rem;
                        }
                    }
                }
            }
            .tap::after{
                top: 1.1rem;
            }
            .wrap{
                    height: 2.4rem;
                    background: url(../assets/PC/wrap.png) repeat-x;
                -webkit-animation:cloud 120s linear infinite;
                -o-animation:cloud 120s linear infinite;
                background-size: auto 100%;
                opacity: 0.8;
                }
            .dialog{
                min-height:6rem;
                .alertDateEr{
                    height: auto;
                    margin-top: 0;
                    .alertDateErCon{
                        position: static;
                        margin: 0 auto;
                        img{
                            width: 2rem;
                            height: 2rem;
                        }
                        span{
                            font-size: 0.24rem;
                        }
                    }
                }
                .logo{
                    width: 2rem;
                    margin-top: 0.4rem;
                }
                h3{
                    font-size: 0.3rem;
                    padding-left:2.3rem;
                    margin-top: 1.4rem;
                    font-weight: 700;
                }
                .title{
                    font-size: 0.24rem;
                    padding-left:2.3rem;
                    line-height: 2;
                    margin-top: 0.20rem;
                    margin-bottom: 0.2rem;
                }
                p{
                    font-size: 00.24rem;
                    padding-bottom: 0.8rem;
                    margin-top: 00.8rem;
                    text-align: justify;
                }
                .herf{
                    right: 0rem;
                    text-decoration: auto;
                    bottom: 0.2rem;
                    a{
                        font-size: 0.28rem;
                            text-decoration: auto;
                    }
                }
                
            }
            .dialogDing{
                min-height:7rem;
                margin: 0;
                
                .logoMobile{
                    display: none;
                }
                .logo{
                    // width: 1.6rem;
                    width: auto;
                    height: 0.64rem;
                    margin-top: 0.4rem;
                }
                h3{
                    font-size: 0.28rem;
                    margin-top: 0.6rem;
                    font-weight: 700;
                }
                .title{
                    font-size: 0.24rem;
                    width: 100%;
                    margin-bottom:0.6rem;
                }
                p{
                    font-size: 0.24rem;
                    // padding-bottom: 1.6rem;
                    width: 100%;
                    padding-top:0.5rem;
                    text-align: justify;
                }
                .text{
                    position: inherit;
                    font-size: 0.24rem;
                    width: 100%;
                }
                .er{
                    text-align: center;
                    margin-top: 00.6rem;
                    img{
                        width: 2rem;
                        height: 2rem;
                        margin-top: 0;
                    }
                    em{
                        font-size: 0.24rem;
                    }
                }
                .herf{
                    position: static;
                    text-align: right;
                    margin-top: 0.8rem;
                    a{
                        
                    font-size: 0.24rem;
                    }
                }
            }
            .dialogDingVide{
                padding: 0;
                .title{
                    font-size: 0.3rem;
                    // width: 100%;
                    padding-bottom: 0.1rem;
                    display: inline-block;
                }
                p{
                    font-size: 0.24rem;
                    // padding-bottom: 1.6rem;
                    width: 100%;
                }
                .text{
                    position: inherit;
                    font-size: 0.24rem;
                    width: 100%;
                    margin-top: 0.2rem;
                    margin-bottom: 0.3rem;
                }
                .video-player{
                    width: 100%;
                    box-sizing: border-box;
                    overflow: hidden;
                    margin-bottom:0.3rem ;
                }
            }
            .partner{
                .title{
                    font-size: 0.34rem;
                    margin: 0.8rem 0;
                }
                .imgs{
                    margin-bottom: 0.8rem;
                    li{
                        width: 33.333%;
                        text-align: center;
                        margin-bottom: 0.6rem;
                    }
                    img{
                        height: 0.38rem;
                    }
                }
            }
        }
    }
</style>